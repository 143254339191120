
// https://iconos8.es/icons/set/next

const iconNext=`>`;

const iconBack=`<`;

const iconAmpliar=`Ampliar`;

const iconVerVideo=`Video`;

const iconBusAvanzada=`Busq. A.`;

const iconOpenSop=`Open`;